import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Configure, ConfigureProps, InstantSearch, SortBy } from "react-instantsearch";
import { history as routerHistory } from 'instantsearch.js/es/lib/routers';

import GlobalContext from '../../../context/global-context';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { FilterableAttributes } from '../../../types/filters';
import { isBrowser } from '../../../utils/browser';
import { generateKey } from '../../../utils/keys';
import {
  createURL,
  getSearchClient,
  searchStateToUrl,
  urlToSearchState
} from "../../../utils/search";
import Loading from '../../global/loading/loading';
import { BusinessHits } from '../businessHits/businessHits';
import NoResults from '../noResults/noResults';
import { RefinementDrawer } from '../refinementDrawer/refinementDrawer';
import { CustomRefinementPagination } from '../refinementPagination/refinementPagination';
import { RefinementPanel } from '../refinementPanel/refinementPanel';
import { CustomRefinement } from '../refinements/refinements';
import Register from '../register/register';
import { CustomStats } from '../stats/stats';
import { CustomSortBy } from '../shared/customSortBy/customSortBy';

import './searchEngine.scss';

type SearchEngineProps = {
  location: Location;
  filtersLoading: boolean;
  filterableAttributes: FilterableAttributes;
};

const SearchEngine : React.FC<SearchEngineProps> = (props) => {
  const { location, filtersLoading, filterableAttributes } = props;
  const { instance, i18n } = useContext(GlobalContext);
  const { width } = useWindowDimensions();

  const searchClient = getSearchClient();
  const indexName = `businesses-${instance?.attributes.locale}-${process.env.GATSBY_MEILI_ENV}`;
  const [initialUiState, setInitialUiState] = useState<any>(
    urlToSearchState({ search: '' })
  );

  function onStateChange(params: { uiState: any, setUiState: (uiState: any) => void}) {
    if (!isBrowser) return;

    const { uiState, setUiState } = params;

    history.replaceState(uiState, '', searchStateToUrl(uiState));

    setInitialUiState(uiState);
    setUiState(uiState);
  };

  useEffect(() => {
    if (filtersLoading) return;

    const locationSearchState: any = urlToSearchState(location);
    if (locationSearchState) {
      // Keep only url search params that have filters
      if (locationSearchState?.refinementList?.naics_categories) {
        let val = locationSearchState?.refinementList?.naics_categories;
        if (typeof val === 'string') val = [val];

        locationSearchState.refinementList.naics_categories = val.filter(
          (filter: string) => {
            return filterableAttributes.naicsCategories?.find(
              (facet) => facet.name === filter
            );
          }
        );
      }
      if (locationSearchState?.refinementList?.based_in_community) {
        let val = locationSearchState?.refinementList?.based_in_community;
        if (typeof val === 'string') val = [val];

        locationSearchState.refinementList.based_in_community = val.filter(
          (filter: string) => {
            return filterableAttributes.communities?.find(
              (facet) => facet.name === filter
            );
          }
        );
      }
      if (locationSearchState?.refinementList?.can_operate_in_communities) {
        let val =
          locationSearchState?.refinementList?.can_operate_in_communities;
        if (typeof val === 'string') val = [val];

        locationSearchState.refinementList.can_operate_in_communities =
          val.filter((filter: string) => {
            return filterableAttributes.communities?.find(
              (facet) => facet.name === filter
            );
          });
      }
      if (locationSearchState?.refinementList?.seasons_of_operation) {
        let val = locationSearchState?.refinementList?.seasons_of_operation;
        if (typeof val === 'string') val = [val];

        locationSearchState.refinementList.seasons_of_operation = val.filter(
          (filter: string) => {
            return filterableAttributes.seasonOfOperations?.find(
              (facet) => facet.name === filter
            );
          }
        );
      }
      if (locationSearchState?.refinementList?.legal_structure) {
        let val = locationSearchState?.refinementList?.legal_structure;
        if (typeof val === 'string') val = [val];

        locationSearchState.refinementList.legal_structure = val.filter(
          (filter: string) => {
            return filterableAttributes.legalStructures?.find(
              (facet) => facet.name === filter
            );
          }
        );
      }

      setInitialUiState(locationSearchState);
    }
  }, [location, filtersLoading, filterableAttributes]);

  const sortOptions = [
    {
      value: indexName,
      label: i18n?.t('sort_by.best_match') ?? 'Best Match',
      isRefined: true
    },
    {
      value: `${indexName}:public_facing_legal_business_name:asc`,
      label: i18n?.t('sort_by.business_asc') ?? 'Business Name A-Z',
      isRefined: false
    },
    {
      value: `${indexName}:public_facing_legal_business_name:desc`,
      label: i18n?.t('sort_by.business_desc') ?? 'Business Name Z-A',
      isRefined: false
    },
    {
      value: `${indexName}:can_operate_in_communities:asc`,
      label: i18n?.t('sort_by.community_asc') ?? 'Community A-Z',
      isRefined: false
    },
    {
      value: `${indexName}:can_operate_in_communities:desc`,
      label: i18n?.t('sort_by.community_desc') ?? 'Community Z-A',
      isRefined: false
    },
    {
      value: `${indexName}:legal_structure:asc`,
      label: i18n?.t('sort_by.legal_structure_asc') ?? 'Legal Structure A-Z',
      isRefined: false
    },
    {
      value: `${indexName}:legal_structure:desc`,
      label: i18n?.t('sort_by.legal_structure_desc') ?? 'Legal Structure Z-A',
      isRefined: false
    },
  ];

  const [isOpened, toggleFiltersMenu] = useState(false);
  const [isSearchResults, setIsSearchResults] = useState(false);
  const [isInitSearch, setIsInitSearch] = useState(true);
  const [filterCount, setFilterCount] = useState(0);
  const [searchResults, setSearchResults] = useState(0);
  const hitsPerPage = 10;
  const configureProps = { hitsPerPage } as ConfigureProps;

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indexName}
      initialUiState={initialUiState}
      onStateChange={onStateChange}
      routing={{
        router: routerHistory({
          createURL: ({ routeState }) => createURL(routeState)
        })
      }}
    >
      <CustomRefinement
        toggle={toggleFiltersMenu}
        isOpened={isOpened}
        setFilterCount={setFilterCount}
      />

      {isInitSearch && (
        <div className="search-body loading">
          <Loading size="medium" />
        </div>
      )}
      <div
        className="search-body"
        style={!isInitSearch && isSearchResults ? {} : { display: 'none' }}
      >
        <Row className="gx-5">
          <Col xs={0} lg={3}>
            <div className="refinement-panel-wrapper">
              {width >= 992 && <RefinementPanel />}
            </div>
            <div className="search-register-wrapper">
              <Register />
            </div>
          </Col>
          <Col lg={9}>
            <Row className="stats">
              <Col xs={4} lg={2} className="hit-counter">
                <CustomStats
                  isInitSearch={isInitSearch}
                  setIsInitSearch={setIsInitSearch}
                  setIsSearchResults={setIsSearchResults}
                  setSearchResults={setSearchResults}
                />
              </Col>
              <Col xs={8} lg={10} className="sort-col">
                <div className="sort">
                  <span className="sort-label">
                    {i18n?.t('business_registry.sort_by')}
                  </span>
                  <CustomSortBy
                    items={sortOptions}
                  />
                </div>
              </Col>
            </Row>
            <BusinessHits />
            <Configure {...configureProps} />
            <CustomRefinementPagination
              id={generateKey('business-refinement-pagination')}
              hitsPerPage={hitsPerPage}
            />
          </Col>
        </Row>
      </div>

      <NoResults
        style={
          isInitSearch || isSearchResults
            ? { display: 'none' }
            : { display: 'block' } 
        }
      />
      {width < 992 && (
        <RefinementDrawer
          toggle={toggleFiltersMenu}
          isOpened={isOpened}
          filterCount={filterCount}
        />
      )}
    </InstantSearch>
  )
};

export default SearchEngine;